

























































































































































































import ChannelLicenseHelper from '@/includes/logic/ChannelLicenseHelper'
import { hasChangeConfigPermission } from '@/includes/PermissionHelper'
import ChannelCardState from '@/components/ChannelCardState.vue'
import { SlimChannelInfo, TestChannelStateCode } from "@/includes/types/Channel.types";
import { BoardPermissions, SlimBoardInfo, TargetState } from "@/includes/types/Board.types";
import { TestChannelResultPayload } from '@/mixins/TestStateMixin';

import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import { fromToTicks } from 'piramis-base-components/src/shared/utils/fromToTicks'
import { ApexChartSeries } from 'piramis-base-components/src/shared/types/ApexChartSeries.types'
import TokenId from 'piramis-base-components/src/shared/utils/TokenId'

import { Emit, Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import VueApexCharts from 'vue-apexcharts'
import { ApexOptions } from 'apexcharts'
import moment from 'moment'
import Vue from "vue";

@Component({
  components: {
    ChannelCardState,
    VueApexCharts,
  },
  data() {
    return {
      TargetState,
      EntityTypes,
      hasChangeConfigPermission,
    }
  }
})
export default class ChannelCard extends Vue {
  @Prop({ required: true, type: Object }) channel !: SlimChannelInfo

  @Prop({ required: false, type: Object }) board!: SlimBoardInfo

  @Prop({ type: Boolean }) changeStateButton!: boolean

  @Prop({ type: Boolean, default: false }) showPrivacyInfo!: boolean

  @Prop({ type: Array, default: null }) permissions!: Array<BoardPermissions> | null

  @Prop({ type: Boolean, default: false }) deleteChannelButton!: boolean

  @Prop() statistics!: ApexChartSeries | null | undefined

  @Emit()
  updateChannelInfo(payload: TestChannelResultPayload) {
    return payload
  }

  @Emit()
  channelStateClick(payload: { state: TestChannelStateCode, entity: 'channel' | 'board' }): { state: TestChannelStateCode, entity: 'channel' | 'board' } {
    return payload
  }

  @Emit()
  updateChannelStateButtonClick(): number {
    return this.channel.id
  }

  @Emit()
  deleteChannel(): number {
    return this.channel.id
  }

  status: TestChannelStateCode | null = null

  notPrincipledStatus: Array<TestChannelStateCode> = [
    TestChannelStateCode.ChannelPermissionAddUsersError,
    TestChannelStateCode.ConnectedGroupNotFoundError,
    TestChannelStateCode.ConnectedGroupPermissionSendMessageError,
    TestChannelStateCode.ConnectedGroupPermissionPinMessageError,
    TestChannelStateCode.ConnectedGroupPermissionDeleteMessageError,
    TestChannelStateCode.ConnectedGroupPermissionEditMessageError,
  ]

  get isStateErrorNotPrinciple() {
    return this.notPrincipledStatus.includes(this.channel.test_state)
  }

  chartOptions: ApexOptions = {
    grid: {
      show: false,
      padding: {
        top: 10,
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
    chart: {
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2.5,
    },
    colors: [ '#69b744', '#fa9ea2' ],
    xaxis: {
      categories: fromToTicks(moment().add(-7, 'd').format(), moment().format(), { format: 'LL' }).timeTicks,
      axisBorder: {
        show: false,
      },
      labels: { show: false },
    },
    yaxis: {
      labels: {
        formatter: (value:any) => parseInt(value).toString(),
      },
    },
    tooltip: {
      x: { show: true },
    },
  }

  channelLicense = new ChannelLicenseHelper(this.channel)

  get tooltipTitle(): string {
    if (this.channelLicense.isLicenseTimeExpired && this.channel.free_licence && this.channel.members_count < 1000) {
      return this.$t('free_license_under_1000').toString()
    } else {
      if (!this.channel.licence_expired) {
        return this.$t('license_expires_with_date', [ this.channelLicense.licenseTimeFormatted ]).toString()
      }
    }

    return ''
  }

  get buyLicenseButtonTitle(): string {
    if (this.channel.licence_expired) {
      return this.$t("button_license_buy").toString()
    } else if (this.channelLicense.isLicenseExpiring) {
      return this.$t('button_license_prolongate').toString()
    } else {
      return ''
    }
  }

  get boardParamKey(): string {
    return TokenId.getId(this.board.board)
  }

  get businessType() {
    if (this.channelLicense.hasFreeLicenseByMembersCount) {
      return this.$t('channel_business_free_license').toString()
    } else {
      return this.channelLicense.licenseType
    }
  }

  channelPrivacyStatusTitle(username?: string) {
    if (username) {
      return this.$t('channel_privacy_public_title').toString()
    } else {
      return this.$t('channel_privacy_private_title').toString()
    }
  }

  gotoRoute(routeName: string): void {
    if (this.channel.state === TargetState.Board) {
      this.$router.push({
        name: routeName,
        params: {
          [EntityTypes.BOT_ID]: this.boardParamKey,
          [EntityTypes.CHANNEL_ID]: this.channel.id.toString()
        },
      })
    }
  }

  destroyed(): void {
    this.$store.commit('resetTestStateCounter')
  }
}

