import { SlimChannelInfo } from '@/includes/types/Channel.types'

import moment from 'moment'

export default class ChannelLicenseHelper {

    channel: SlimChannelInfo

    static LICENSE_TYPES = {
        '0': '',
        '1': 'Business'
    }

    constructor(channel:SlimChannelInfo) {
        this.channel = channel
    }

    get licenseType() {
        return ChannelLicenseHelper.LICENSE_TYPES[this.channel.license_type]
    }

    get licenseTimeFormatted() {
        return moment(this.channel.license_time).format('L, HH:mm')
    }

    get hasFreeLicenseByMembersCount() {
        return this.isLicenseTimeExpired && this.channel.free_licence && this.channel.members_count < 1000
    }

    get isLicenseTimeExpired() {
        return moment().isAfter(this.channel.license_time)
    }

    get isLicenseExpires() {
        return moment(this.channel.license_time).diff(moment(), 'd') < 7
            && moment(this.channel.license_time).isSameOrAfter(moment())
    }

    get isLicenseExpiring() {
        return !this.channel.licence_expired && moment(this.channel.license_time).diff(moment(), 'd') <= 3
    }
}