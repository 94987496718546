
















































import { hasChangeConfigPermission } from '@/includes/PermissionHelper'
import TestStateMixin from '@/mixins/TestStateMixin'
import TestStateInfoModal from '@/components/TestStateInfoModal.vue'
import { ChannelInfo, TestChannelStateCode } from "@/includes/types/Channel.types";
import { BoardPermissions, SlimBoardInfo } from "@/includes/types/Board.types";

import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  components: {
    TestStateInfoModal
  },
  data() {
    return {
      hasChangeConfigPermission
    }
  }
})
export default class ChannelCardState extends Mixins(TestStateMixin) {

  @Prop() type!: 'large' | 'slim'

  @Prop({ required: true, type: Object }) channel!: ChannelInfo

  @Prop({ required: false, type: Object }) board!: SlimBoardInfo

  @Prop({ type: Array, default: () => [] }) permissions!:Array<BoardPermissions> | null

  onChannelStateClick() {
    this.handleEntityStateClick({ state: this.channel.test_state, entity: 'channel' })
  }

  handleTestChannelStatusClick(event: Event): void {
    if (this.buttonDisabled) {
      event.preventDefault()
    } else {
      this.testChannelStatus(this.board.board, this.channel.id)
    }
  }

  created() {
    if (this.channel.test_state === TestChannelStateCode.None && this.$store.state.testStateCounter < 3) {
      this.$store.commit('updateTestStateCounter')
      this.testChannelStatus(this.board.board, this.channel.id)
    }
  }
}
